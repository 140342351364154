import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class CartService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/cart`;
      }

      getCart() {
            let url = `${this.#api}`;
            return apiService.get(url);
      }

      addToCart(data) {
            let url = `${this.#api}`;
            return apiService.post(url, data);
      }

      delete(cartId) {
            let url = `${this.#api}/${cartId}`;
            return apiService.delete(url);
      }

      deleteItem(cartId, itemId) {
            let url = `${this.#api}/${cartId}/item/${itemId}`;
            return apiService.delete(url);
      }

      payOffline(cartId) {
            let url = API_URL + 'user/payment/offline/' + cartId ;
            return apiService.get(url);
      }
      esewa(cartId, data) {
            let url = API_URL + 'user/payment/esewa/' + cartId;
            return apiService.post(url, data)
      }

      //Method is prepared for admin
      createNewPreregistration(data){
            let url = API_URL + 'user/payment/esewa/' + cartId;
            return apiService.post(url, data)
      }



}
