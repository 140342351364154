<template>
  <v-app>
    <!-- end:: Content -->
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          <h3>Cart</h3>
        </div>

        <div class="row" v-if="cart && cart != undefined">
          <div class="col-sm-12 col-md-8 col-lg-8">
            <div class="row" v-for="(item, index) of items" :key="index">
              <div class="col-12">
                <v-card class="mx-auto my-2" outlined>
                  <v-card-text>
                    <div class="row" style="align-items: center;">
                      <div class="col-md-2">
                        <v-img
                          aspect-ratio="1"
                          max-height="150"
                          max-width="150"
                          src="media/logos/logo_small.jpeg"
                        ></v-img>
                      </div>
                      <div class="col-md-6">
                        <div class="text-dark font-weight-bolder mb-1 font-size-lg">{{item.title}}</div>
                        <p
                          class="text-dark font-weight-bolder mb-1 font-size-lg"
                        >{{item.enrollment.program}}</p>
                        <p class="font-weight-black">
                          Level :
                          <strong
                            v-if="item.enrollment.level"
                          >{{item.enrollment.level.toUpperCase()}}</strong>
                        </p>
                        <p class="font-weight-black">
                          Program :
                          <strong
                            v-if="item.enrollment.program"
                          >{{item.enrollment.program.toUpperCase()}}</strong>
                        </p>
                        <p class="font-weight-black">
                          Student Name :
                          <strong>{{item.enrollment.full_name}}</strong>
                        </p>
                      </div>
                      <div class="col-md-4">
                        <div class>
                          <p
                            class="text-dark font-weight-bolder mb-1 font-size-lg"
                          >Rs. {{item.item_price}}</p>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-4 col-lg-4">
            <v-card outlined>
              <v-card-title>
                <p class="text-center">Summary</p>
              </v-card-title>
              <v-card-text>
                <div class="row m-2" v-if="cart['cart_total']">
                  <div class="col-6">
                    <p class="text-dark font-weight-bolder font-size-md">Sub Total</p>
                  </div>
                  <div class="col-6">
                    <p class="text-dark font-weight-bolder font-size-md float-right">
                      Rs.
                      {{cart['cart_total']['sub_total']}}
                    </p>
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="row m-2" v-if="cart['cart_total']">
                  <div class="col-6">
                    <p class="text-dark font-weight-bolder font-size-md">Tax</p>
                  </div>
                  <div class="col-6">
                    <p class="text-dark font-weight-bolder font-size-md float-right">
                      Rs.
                      {{cart['cart_total']['tax']}}
                    </p>
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="row m-2" v-if="cart['cart_total']">
                  <div class="col-6">
                    <p class="text-dark font-weight-bolder font-size-md">Total</p>
                  </div>
                  <div class="col-6">
                    <p class="text-dark font-weight-bolder font-size-md float-right">
                      Rs.
                      {{cart['cart_total']['total']}}
                    </p>
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="row m-2">
                  <div class="col-4">
                    <p class="my-4 text-dark font-weight-bolder font-size-md">Payment Options</p>
                  </div>
                  <div class="col-4">
                    <v-card
                      :outlined="paymentOption=='esewa'"
                      @click.prevent="choosePaymentOption('esewa')"
                    >
                      <v-img
                        src="/media/payment-options/esewa-logo.png"
                        :aspect-ratio="16/9"
                        width="auto"
                        height="50"
                        contain
                        alt="esewa"
                      ></v-img>
                    </v-card>
                  </div>
                  <div class="col-4">
                    <v-card
                      :outlined="paymentOption=='offline'"
                      @click.prevent="choosePaymentOption('offline')"
                    >
                      <v-img
                        src="/media/payment-options/offline-payments.png"
                        :aspect-ratio="16/9"
                        width="auto"
                        height="50"
                        contain
                        alt="esewa"
                      ></v-img>
                    </v-card>
                  </div>
                  <span
                    class="text-danger"
                    v-if="$v.valid.payOption.$error"
                  >Please Choose Payment One Option</span>
                </div>
                <v-divider></v-divider>

                <div class="row m-2">
                  <div class="col-12">
                    <v-btn block depressed class="btn btn-primary" @click="checkout()">Checkout</v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>

        <div v-else>Cart is Empty</div>

        <!--            <div class="row" v-if="cart && cart != undefined">-->
        <!--                <div class=" col-sm-12 col-md-8 col-lg-8">-->
        <!--                    <v-card-->
        <!--                            outlined-->
        <!--                    >-->
        <!--                        <v-card-title>-->
        <!--                            Cart Items-->
        <!--                        </v-card-title>-->
        <!--                        <v-card-text>-->
        <!--                            <div class="row m-2">-->
        <!--                                <div class="col-8">-->
        <!--                                    <p class="font-weight-bold ">Item</p>-->
        <!--                                </div>-->
        <!--                                <div class="col-4 text-right">-->
        <!--                                    <p class="font-weight-bold ">Price</p>-->
        <!--                                </div>-->

        <!--                            </div>-->
        <!--                            <v-divider-->

        <!--                            ></v-divider>-->
        <!--                            <div class="row m-2" v-for="(item, index) of items" :key="index">-->
        <!--                                <div class="col-8">-->
        <!--                                    <p class="font-weight-bold ">{{item.title}}</p>-->
        <!--                                </div>-->
        <!--                                <div class="col-4 text-right">-->
        <!--                                    <p class="font-weight-bold ">Rs.{{item.item_price}}</p>-->
        <!--                                </div>-->

        <!--                            </div>-->

        <!--                        </v-card-text>-->
        <!--                    </v-card>-->

        <!--                </div>-->
        <!--                <div class=" col-sm-12 col-md-4 col-lg-4">-->
        <!--                    <v-card-->

        <!--                    >-->
        <!--                        <v-card-title>-->
        <!--                            <p class="text-center">-->
        <!--                                Summary-->

        <!--                            </p>-->
        <!--                        </v-card-title>-->
        <!--                        <v-card-text>-->
        <!--                            <div class="row m-2" v-if="cart['cart_total']">-->
        <!--                                <div class="col-6">-->
        <!--                                    <p class="font-weight-medium subtitle-1">Sub Total</p>-->
        <!--                                </div>-->
        <!--                                <div class="col-6">-->
        <!--                                    <p class="font-weight-bold float-right">Rs. {{cart['cart_total']['sub_total']}}</p>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                            <v-divider-->

        <!--                            ></v-divider>-->
        <!--                            <div class="row m-2" v-if="cart['cart_total']">-->
        <!--                                <div class="col-6">-->
        <!--                                    <p class="font-weight-medium subtitle-1">Tax</p>-->
        <!--                                </div>-->
        <!--                                <div class="col-6">-->
        <!--                                    <p class="font-weight-bold float-right">Rs. {{cart['cart_total']['tax']}}</p>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                            <v-divider-->

        <!--                            ></v-divider>-->
        <!--                            <div class="row m-2" v-if="cart['cart_total']">-->
        <!--                                <div class="col-6">-->
        <!--                                    <p class="font-weight-medium subtitle-1">Total</p>-->
        <!--                                </div>-->
        <!--                                <div class="col-6">-->
        <!--                                    <p class=" font-weight-bold float-right">Rs. {{cart['cart_total']['total']}}</p>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                            <v-divider-->

        <!--                            ></v-divider>-->
        <!--                        </v-card-text>-->
        <!--                    </v-card>-->

        <!--                </div>-->
        <!--            </div>-->
        <!--            <div class=" mt-3" v-if="cart && cart != undefined">-->
        <!--                <div class="row">-->
        <!--                    <div class="col-12">-->
        <!--                        <v-card outlined>-->
        <!--                            <v-card-title>-->
        <!--                                Available Payment Options-->
        <!--                            </v-card-title>-->
        <!--                            <v-card-text>-->
        <!--                                <div class="row">-->

        <!--                                    <div class="col-3">-->
        <!--                                        <a-->
        <!--                                                href="#"-->
        <!--                                                @click="esewaPayment(cart.id, cart['cart_total']['tax'], cart['cart_total']['total'])"-->
        <!--                                        >-->
        <!--                                            <b-img src="/media/payment-options/esewa-logo.png" fluid-->
        <!--                                                   alt="esewa"></b-img>-->
        <!--                                        </a>-->
        <!--                                    </div>-->
        <!--                                    <div class="col-3">-->
        <!--                                        <a href="#">-->
        <!--                                            <b-img src="/media/payment-options/offline-payments.png" fluid-->
        <!--                                                   alt="offline"></b-img>-->
        <!--                                        </a>-->
        <!--                                    </div>-->
        <!--                                </div>-->
        <!--                            </v-card-text>-->
        <!--                        </v-card>-->
        <!--                    </div>-->

        <!--                </div>-->
        <!--            </div>-->
        <!--            <div v-else>-->
        <!--                Cart is Empty-->
        <!--            </div>-->
      </div>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Offline Payment Instruction</v-card-title>

        <v-card-text
          v-if="setting.offline_payment_instruction"
          v-html="setting.offline_payment_instruction"
        ></v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <!--                    <v-btn-->
          <!--                            color="green darken-1"-->
          <!--                            text-->
          <!--                            @click="dialog = false"-->
          <!--                    >-->
          <!--                        Disagree-->
          <!--                    </v-btn>-->

          <v-btn color="green darken-1" text @click="dialog = false">Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import CartService from "@/core/services/cart/CartService";
import { required } from "vuelidate/lib/validators";
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";

const siteSettingService = new SiteSettingService();

const cartService = new CartService();
export default {
  name: "cart",
  validations: {
    valid: {
      payOption: { required }
    }
  },
  data() {
    return {
      cart: {},
      items: [],
      overlay: false,
      dialog: false,
      edit: false,
      setting: {},
      paymentOption: null,
      valid: {
        payOption: null
      }
    };
  },
  mounted() {
    this.getSetting();
    this.getCart();
  },
  beforeUpdate() {
    let queries = this.$route.query;
    if (this.cart && queries && queries.oid && queries.refId && queries.amt) {
      queries.cart_id = this.cart.id;
      this.esewaVerfiy(queries);
    }
  },
  methods: {
    getSetting() {
      siteSettingService.show().then(response => {
        this.setting = response.data;
        if (this.setting && this.setting.id) {
          this.edit = true;
        }
      });
    },
    getCart() {
      cartService
        .getCart()
        .then(response => {
          this.cart = response.data;
          this.items = this.cart.items;
        })
        .catch(error => {
          // console.log(error);
        });
    },
    choosePaymentOption(option) {
      this.valid.payOption = true;
      if (option == "esewa") {
        this.paymentOption = "esewa";
      } else {
        this.dialog = true;
        this.paymentOption = "offline";
      }
    },
    checkout() {
      this.$v.valid.$touch();
      if (this.$v.valid.$error) {
        setTimeout(() => {
          this.$v.valid.$reset();
        }, 3000);
      } else {
        this.paymentOption == "esewa"
          ? this.esewaPayment(
              this.cart.id,
              this.cart["cart_total"]["tax"],
              this.cart["cart_total"]["total"]
            )
          : this.payOffline();
      }
    },
    payOffline() {
      this.dialog = false;
      this.$router.push({ name: "dashboard" });
    },
    esewaPayment: function(cartId, tax, total) {
      let successUrl = process.env.VUE_APP_ESEWA_SUCCESS_URL;
      let errorUrl = process.env.VUE_APP_ESEWA_ERROR_URL;
      var path = process.env.VUE_APP_ESEWA_PAYMENT_URL;
      var params = {
        amt: parseFloat(total) - parseFloat(tax),
        psc: 0,
        pdc: 0,
        txAmt: tax,
        tAmt: total,
        pid: "KIST-" + cartId,
        scd: process.env.VUE_APP_ESEWA_MERCHANT,
        su: successUrl,
        fu: errorUrl
      };

      var form = document.createElement("form");
      form.setAttribute("method", "POST");
      form.setAttribute("action", path);

      for (var key in params) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);
        form.appendChild(hiddenField);
      }

      document.body.appendChild(form);
      form.submit();
    },
    esewaVerfiy(queries) {
      let user = this.$store.getters.currentUser;
      let data = queries;
      //   data.payer_name = user.first_name + " " + user.last_name;
      //   data.payer_email = user.email;
      //   data.payer_phone = user.phone;
      //   data.payer_address = window.localStorage.getItem("payer_address");
      this.overlay = true;
      cartService
        .esewa(data.cart_id, data)
        .then(response => {
          if (response) {
            window.localStorage.removeItem("payer_address");
            this.$snotify.success("Payment has been completed");
            let query = {};
            this.$router.replace({ query });
            this.overlay = false;
            this.$router.push({ name: "payment-success" });
          } else {
            this.$snotify.error("Error occured");
          }
        })
        .catch(error => {
          this.$snotify.error("Error occured");
        });
    }
  }
};
</script>